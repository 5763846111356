import { simpleQueryParser } from '../../utility/simple-query-parser';

let query = simpleQueryParser();

if (query.cmp) {
  let expireAt = new Date();

  expireAt.setTime(expireAt.getTime() + (60 * 24 * 60 * 60 * 1000));

  document.cookie = `_adcampaign=${query.cmp};expires=${expireAt.toUTCString()};path=/`;
}
