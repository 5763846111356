export function simpleQueryParser() {
  let search = document.location.search;

  if (!search) search = ``;

  return search
    .substring(1)
    .split('&')
    .map(x => x.split('='))
    .reduce((r, x) => {
      r[x[0]] = decodeURIComponent(x[1]);
      return r;
    }, {});
}
